exports.onInitialClientRender = () => {

  require('typeface-lato')
  require('typeface-open-sans')

  let width = window.screen.width
  document.body.style.backgroundImage = "url('//res.cloudinary.com/mazedonia/image/upload/c_scale,f_auto,w_" + width + "/v1553364733/terminal.es/background')"

  if (document.getElementById("search") != null) {
    let search = document.createElement("script")
    search.setAttribute("charset","utf-8")
    search.setAttribute("src", "//www.travelpayouts.com/widgets/b2f00c493c4d1a169691f527584207b4.js?v=1695")
    search.setAttribute("async", "async")
    document.getElementById("search").appendChild(search)
  }

  if (document.getElementById("destination-1") != null) {
    let destination1 = document.createElement("script")
    destination1.setAttribute("charset", "utf-8")
    destination1.setAttribute("src", "//www.travelpayouts.com/weedle/widget.js?marker=219585&host=search.jetradar.com&locale=es&currency=eur&powered_by=false&destination=BCN&destination_name=Barcelona")
    destination1.setAttribute("async", "async")
    document.getElementById("destination-1").appendChild(destination1)
  }

  if (document.getElementById("destination-2") != null) {
    let destination2 = document.createElement("script")
    destination2.setAttribute("charset", "utf-8")
    destination2.setAttribute("src", "//www.travelpayouts.com/weedle/widget.js?height=300&marker=219585&host=search.jetradar.com&locale=es&currency=eur&powered_by=false&destination=MAD&destination_name=Madrid")
    destination2.setAttribute("async", "async")
    document.getElementById("destination-2").appendChild(destination2)
  }

}